import React from "react";
import AuthUtils from "../utils/AuthUtils";
import { gql } from "apollo-boost";
import { useLazyQuery } from "@apollo/react-hooks";
import { getValueFromLocalStorage } from "../utils/local-storage";
import { getValueFromCookie } from "../utils/cookies";

export const BANK_NAMES = {
  FEDERAL: "Federal Bank",
  FINCARE: "Fincare",
  TPF : "Triplicane Permanent Fund",
};

const GET_USERS_BANK_NAME = gql`
  query getUsersBankName($authId: String!) {
    users(where: { auth_id: { _eq: $authId } }) {
      branch {
        bank {
          name
        }
      }
    }
  }
`;

const BankDetailsContext = React.createContext({
  currentBankName: "",
});

export const BankDetailsProvider = ({ children }) => {
  const [currentBankName, setBankName] = React.useState(BANK_NAMES.FEDERAL);
  const value = React.useMemo(() => ({ currentBankName }), [currentBankName]);

  const [getBankNameByCustomerAuthId, { data }] = useLazyQuery(
    GET_USERS_BANK_NAME,
    {
      notifyOnNetworkStatusChange: true,
      fetchPolicy: "no-cache",
    }
  );

  React.useEffect(() => {
    const userAuthId = getValueFromCookie("user")?.auth_id;

    getBankNameByCustomerAuthId({ variables: { authId: String(userAuthId) } });
  }, []);

  React.useEffect(() => {
    if (data) {
      setBankName(data?.users[0]?.branch?.bank?.name || BANK_NAMES.FEDERAL);
    }
  }, [data]);

  return (
    <BankDetailsContext.Provider value={value}>
      {children}
    </BankDetailsContext.Provider>
  );
};

export const useBankDetails = () => {
  const context = React.useContext(BankDetailsContext);

  if (!context) {
    throw new Error("useBankDetails must be used within a BankDetailsProvider");
  }

  return context;
};
